var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Fonte de dados - e-CAC"),
      ]),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pl-0 pr-0" },
            [
              _c("TableSearch", {
                attrs: { label: "Pesquisar...", delay: 0 },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("v-spacer"),
              _c("v-spacer"),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.ecacProcess,
              search: _vm.search,
              "sort-by": ["createdAt"],
              "sort-desc": [true],
              "footer-props": {
                "items-per-page-options": [10, 25, 50],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.createdAt",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("date")(item.createdAt, {
                            mask: "DD/MM/YYYY HH:mm:ss",
                          })
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.cnpjEmpresa",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("cnpj")(item.cnpjEmpresa)) + " "
                    ),
                  ]
                },
              },
              {
                key: "item.statusProcess",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.statusParse.text) + " ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }