<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Fonte de dados - e-CAC</h2>
    <v-card>
      <v-card-title class="pl-0 pr-0">
        <TableSearch
            label="Pesquisar..."
            v-model="search"
            :delay="0"
          ></TableSearch>
        <v-spacer />
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="ecacProcess"
        :search="search"
        :sort-by="['createdAt']"
        :sort-desc="[true]"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | date({ mask: 'DD/MM/YYYY HH:mm:ss' }) }}
        </template>
        <template v-slot:item.cnpjEmpresa="{ item }">
          {{ item.cnpjEmpresa | cnpj }}
        </template>
        <template v-slot:item.statusProcess="{ item }">
          {{ item.statusParse.text }}
          <!--<slot name="statusProcess">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind:class="{
                    blink: item.statusProcess.includes('PROCESSING') && processing,
                  }"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.statusParse.text }}
                </v-chip>
              </template>
              <span>{{ item.errorCause }}</span>
            </v-tooltip>
          </slot>-->
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<script>
import 'moment/locale/pt-br';

import ecacService from '@/services/ecacService.js';

import Main from '../../layouts/Main.vue';

export default {
  components: { 
    Main,
    TableSearch: () => import('../commons/TableSearch.vue'),
  },
  data() {
    return {
      search: '',
      error: false,
      headers: [
        { text: 'Qtd. DCTF', value: 'amountDctf', sortable: false },
        { text: 'Data', value: 'createdAt' },
        { text: 'Usuário', value: 'userLogged' },
        { text: 'CNPJ empresa', value: 'cnpjEmpresa' },
        { text: 'Situação do processo', value: 'statusProcess' },
      ],

      ecacProcess: [],
      loader: null,
      loading: false,
      processing: false,
      finishSetInterval: true,
    };
  },

  mounted() {
    this.getAll();
    this.getAllInterval();
  },

  methods: {
    async getAll() {
      this.error = false;
      try {
        const response = await ecacService.ecacProcess.getAll();
        this.loading = false;
        this.ecacProcess = response.data.content;

        this.ecacProcess.map((u) => {
          u.errorCause = u.statusProcess == 'ERROR_PROCESS' ? u.errorMessage : 'Processo finalizado!';
          u.statusParse = this.getStatusOptions(u.statusProcess);
        });

        if (this.ecacProcess[0].statusProcess.includes('PROCESSING')) {
          this.processing = true;
          this.finishSetInterval = false;
          this.ecacProcess[0].errorCause = 'Em processo...';
        } else {
          this.processing = false;
          this.finishSetInterval = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },

    getStatusOptions(statusProcess) {
      if (statusProcess.includes('FINISHED_PROCES')) {
        return { text: 'Finalizado' };
      } else if (statusProcess.includes('PROCESSING')) {
        return { text: 'Processando' };
      } else if (statusProcess.includes('ERROR_PROCESS')) {
        return { text: 'Erro' };
      } else {
        return { text: statusProcess };
      }
    },

    async startIndex() {
      this.finishSetInterval = false;
      this.loading = true;
      this.error = false;
      try {
        await ecacService.ecacProcess.startIndex();
        this.getAllInterval();
      } catch (error) {
        this.loading = false;
        this.processing = false;
        this.finishSetInterval = false;
        this.error = true;
      }
    },

    getAllInterval() {
      let myInterval = setInterval(() => {
        this.getAll();
        if (this.finishSetInterval) {
          clearInterval(myInterval);
        }
      }, 30000);
    },
  },
};
</script>

<style scoped>
.mt {
  margin-top: 100px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes blink-effect {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
.blink {
  -webkit-animation: blink-effect 0.75s linear infinite;
  -moz-animation: blink-effect 0.75s linear infinite;
  -ms-animation: blink-effect 0.75s linear infinite;
  -o-animation: blink-effect 0.75s linear infinite;
  animation: blink-effect 0.75s linear infinite;
}
</style>
